@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');
body {
    background-color: #000;
    font-family: Inter, sans-serif;
    color: #fff;
    .text-muted {
        color: #999;
    }
}

@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://assets.website-files.com/61eeeb8b192446d376ffa8c1/61eeeda806b1da5589341c4e_PlusJakartaSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://assets.website-files.com/61eeeb8b192446d376ffa8c1/61eeeda97348497a3ac63f8c_PlusJakartaSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://assets.website-files.com/61eeeb8b192446d376ffa8c1/61eeeda85b7dc760477c71fa_PlusJakartaSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://assets.website-files.com/61eeeb8b192446d376ffa8c1/61eeeda9d6a7a8f1ce227096_PlusJakartaSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://assets.website-files.com/61eeeb8b192446d376ffa8c1/61eeeda92d38a921525b169c_PlusJakartaSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://assets.website-files.com/61eeeb8b192446d376ffa8c1/61eeeda9d3212e63dbe9087c_PlusJakartaSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
}

.container {
    padding-top: 140px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.container-fluid {
    padding-top: 140px;
}

.navbar {
    position: fixed;
    font-size: 14px;
    z-index: 4;
    width: 100%;
    padding: 8px 16px;
    .navbar-brand {
        display: flex;
        margin-left: -4px;
        padding: 4px 8px;
        transition: background-color .2s ease;
        border-radius: 6px;
        &:hover {
            background-color: hsla(0, 0%, 100%, .08);
        }
        span {
            color: #fff;
            font-size: 14px;
        }
        img {
            width: 32px;
        }
    }
    .nav-item {
        padding-left: 8px;
        a.nav-link {
            display: flex;
            padding: 4px 8px;
            border-radius: 6px;
            transition: background-color .2s ease;
            text-decoration: none;
            color: #fff;
            &.active {
                background-color: hsla(0, 0%, 100%, .08);
                color: #fff;
            }
            &:hover {
                background-color: hsla(0, 0%, 100%, .08);
                color: #fff;
            }
            &.button_small {
                border: 1.5px solid #fff;
                padding: 2px 8px;
                &:hover {
                    background-color: #fff !important;
                    color: #111 !important;
                }
            }
        }
    }
}

.link-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.form-control {
    background: #ffffff20;
    border: 0;
    padding: 8px 16px;
    transition: opacity .3s ease;
    color: #fff;
    &:hover {
        opacity: .8;
    }
    &:focus {
        background: #ffffff20;
        border: 0;
        opacity: .8;
        color: #fff;
    }
}

.gradient-heading {
    font-weight: 900;
    font-size: 100px;
    line-height: 130px;
    background: linear-gradient(90deg, #b13cff, #dd45d3 50%, #fd9d52);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heading {
    font-weight: 900;
    font-size: 125px;
    line-height: 130px;
    letter-spacing: 6px;
    background: linear-gradient(255deg, #b13cff, #dd45d3 50%, #fd9d52);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &.alt {
        background: linear-gradient(138deg, #b13cff, #dd45d3 50%, #fd9d52);
    }
}

.heading-alt {
    font-size: 120px;
    line-height: 125px;
    letter-spacing: 6px;
    background: linear-gradient(0deg, #b13cff, #dd45d3 50%, #fd9d52);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subheading {
    font-size: 32px;
    line-height: 40px;
}

.gradient-text {
    background-image: linear-gradient(97deg, #b13cff, #dd45d3 50%, #fd9d52);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text {
    color: #ccc;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
}

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.invert {
    filter: invert(100%);
    transition: all 0.5s ease;
}

.no-invert {
    filter: invert(0%);
}

::selection {
    background: #494949;
    color: #000;
}

a:hover,
a:focus,
a:active {
    text-decoration: none !important;
}

.bg-invert {
    background-color: #100d08;
    transition: all 1s linear 0s;
}

.intro {
    h1 {
        font-weight: 900;
        font-size: 80px;
        line-height: 90px;
    }
    h2 {
        font-weight: 900;
        font-size: 34px;
        line-height: 44px;
        letter-spacing: 2px;
    }
    p {
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
    }
}

.social-items {
    a {
        padding: 6px;
        border-radius: 6px;
        transition: background-color .2s ease;
        color: #fff;
        font-size: 20px;
    }
    a:hover {
        background-color: hsla(0, 0%, 100%, .15);
    }
}

#modeToggle {
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: 9;
    cursor: pointer;
    .fa-stack {
        font-size: 1.5em;
    }
    .fa-adjust {
        color: #000;
    }
}

.btn-gradient {
    background-image: linear-gradient(288deg, #b13cff, #dd45d3 50%, #fd9d52);
    background-repeat: no-repeat;
    border: #000;
    color: #fff;
    &:hover {
        color: #fff;
    }
}

.logo_float {
    position: fixed;
    bottom: -40px;
    right: -40px;
    width: 300px;
    opacity: .1;
}

.gradient-stripe {
    background-image: linear-gradient(90deg, #b13cff, #dd45d3 50%, #fd9d52);
    height: 6px;
    width: 100%;
}

.gradient-stripe-alt {
    background-image: linear-gradient(288deg, #b13cff, #dd45d3 50%, #fd9d52);
    height: 6px;
    width: 100%;
}

.bg-gradient {
    background-image: linear-gradient(90deg, #b13cff, #dd45d3 50%, #fd9d52) !important;
}

.hover-grad:hover {
    background: #1111 !important;
    color: #fff;
}

.loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 9999999;
    img {
        width: 100px;
    }
    .text-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .loading {
        width: 90px;
        margin: 0 auto;
        border-radius: 10px;
        position: relative;
        padding: 1px;
        .bar {
            position: absolute;
            border-radius: 10px;
            top: 0;
            bottom: 0;
            background: #fff;
            animation: animateLoad 1.5s linear infinite;
        }
    }
}

@keyframes animateLoad {
    0% {
        left: 0;
        right: 100%;
        width: 0;
    }
    10% {
        left: 0;
        right: 75%;
        width: 25%;
    }
    90% {
        right: 0;
        left: 75%;
        width: 25%;
    }
    100% {
        left: 100%;
        right: 0;
        width: 0;
    }
}


/* Briefer card */

.briefer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    img {
        width: 90px;
        border-radius: 50%;
    }
    p {
        font-size: 21px;
        line-height: 29px;
        font-weight: 600;
        text-align: center;
        margin: 18px 3rem 0;
    }
}

.thin-link {
    margin-top: 20px;
    padding: 6px 10px;
    border-radius: 6px;
    transition: color .2s ease, background-color .2s ease;
    color: #555;
    font-size: 14px;
    line-height: 18px;
    display: block;
    &:hover {
        background-color: hsla(0, 0%, 100%, .08);
        color: #fff;
    }
}

.d-min-vh-100 {
    min-height: 100vh;
}

.btn-custom {
    text-decoration: none;
    padding: 15px 25px 15px 25px;
    background-color: #fff;
    transition: all 0.2s ease;
    border-radius: 6px;
    &.rounded-ex {
        border-radius: 10px;
    }
}

.heading.heading-lg {
    font-size: 100px;
    line-height: 115px;
}


/* Testimonial */

.testimonial {
    background-color: rgb(11 11 11 / 75%);
    padding: 24px 24px 24px 30px;
    border-radius: 0 6px 6px 0;
    blockquote {
        font-size: 21px;
        line-height: 29px;
        font-weight: 600;
        padding: 10px 20px;
    }
}


/* Swiper styles */

.swiper-slide {
    width: auto !important;
}

.swiper-container-free-mode>.swiper-wrapper {
    transition-timing-function: linear;
}

.card {
    border-radius: 6px;
    background-color: #1a1a1a;
    transition: background-color .2s ease;
    text-decoration: none;
    &.hover:hover {
        background-color: #272727;
    }
    .title {
        color: #fff;
        font-size: 21px;
        line-height: 29px;
        font-weight: 600;
    }
    .subtitle {
        font-size: 14px;
    }
}

.rounded {
    border-radius: 6px !important;
}

.x-small {
    font-size: .875em;
}

.card .icon {
    margin-top: -36px;
}

.divider {
    border: 1px solid #343a40;
}

.large-block {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
}

a[data-fancybox] img {
    cursor: zoom-in;
}

.fancybox__backdrop::after {
    content: "";
    position: absolute;
    width: 10%;
    height: 10%;
    filter: blur(2px);
    left: 50%;
    top: 50%;
    transform: scale(11);
    opacity: 0.3;
    background-image: var(--bg-image);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.fancybox__container {
    --fancybox-bg: #000;
    --fancybox-thumbs-width: 48px;
    --fancybox-thumbs-ratio: 1;
    --carousel-button-bg: rgb(91 78 76 / 74%);
    --carousel-button-svg-width: 24px;
    --carousel-button-svg-height: 24px;
    --carousel-button-svg-stroke-width: 2.5;
}

.fancybox__nav {
    --carousel-button-svg-width: 24px;
    --carousel-button-svg-height: 24px;
    .carousel__button.is-prev {
        left: 20px;
    }
    .carousel__button.is-next {
        right: 20px;
    }
}

.carousel__button.is-close {
    right: auto;
    top: 20px;
    left: 20px;
}

.fancybox__slide {
    padding: 8px 88px;
}

.fancybox__thumbs .carousel__slide {
    padding: 8px 8px 16px 8px;
}

.is-nav-selected::after {
    display: none;
}

.fancybox__thumb {
    border-radius: 6px;
    opacity: 0.4;
    &:hover,
    .is-nav-selected .fancybox__thumb {
        border-radius: 6px;
        opacity: 1;
    }
}

.is-nav-selected .fancybox__thumb::after {
    display: none;
}

h1,
h3,
h4 {
    font-weight: 700;
    margin-bottom: 10px;
}

h1,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 700;
}

h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
}

blockquote {
    margin: 0 0 10px;
    line-height: 32px;
}

label {
    margin-bottom: 5px;
}

h1 {
    font-size: 40px;
    line-height: 48px;
}

blockquote,
h3 {
    font-size: 24px;
}

h3 {
    line-height: 30px;
}

p {
    margin-bottom: 32px;
}

a {
    line-height: 24px;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
}


/* Buttons */

.button {
    margin-top: 0;
    margin-left: 8px;
    padding: 8px 16px;
    border-radius: 6px;
    background-color: #111;
    transition: border .2s ease, background-color .2s ease, color .2s ease;
    color: #fff;
    font-weight: 500;
    text-align: center;
    &.primary {
        background-color: #111;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .4);
        &.text-white {
            background-color: #fff;
            color: #111;
        }
    }
    &.secondary {
        background-color: transparent;
        text-decoration: none;
        margin-top: 24px;
        &:hover {
            background-color: hsla(0, 0%, 100%, .08);
        }
    }
    &.alt {
        background-color: hsla(0, 0%, 100%, .08);
        text-decoration: none;
        margin-top: 24px;
    }
}

.button-3 {
    padding: 10px 16px;
    border-radius: 6px;
    background-color: #111;
    transition: border .2s ease, background-color .2s ease, color .2s ease;
    color: #fff;
    font-weight: 500;
    &.primary {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .4);
        background-color: #fff;
        color: #111;
    }
}

#viewer {
    height: 970px;
}

.submit-button {
    padding: 10px 22px;
    border-radius: 6px;
    background-color: #fff;
    color: #000;
    line-height: 24px;
    font-weight: 500;
}

.small {
    font-size: 15px;
}

.headshot {
    width: 150px;
}

@media (max-width: 575.98px) {
    .container {
        padding-top: 70px;
    }
    .navbar {
        background: #ffffff10;
        span {
            display: none;
        }
    }
    .intro {
        h1 {
            font-size: 37px;
            line-height: 19px;
        }
        h2 {
            font-size: 18px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
    section {
        .subheading {
            font-size: 27px;
            line-height: 28px;
        }
        .text {
            font-size: 15px;
            line-height: 24px;
        }
    }
    .testimonial blockquote {
        font-size: 17px;
        line-height: 24px;
    }
    .briefer p {
        font-size: 17px;
        line-height: 26px;
    }
    .inquiry .subheading {
        font-size: 26px;
    }
    .small {
        font-size: 13px;
    }
    .d-min-vh-100 {
        min-height: initial;
    }
    .intro .social-items a {
        font-size: 16px;
    }
    .logo_float {
        display: none;
    }
    .intro.container {
        padding: 40px 1.5rem;
    }
    .heading-alt,
    .heading {
        font-size: 59px;
        line-height: 67px;
    }
    .headshot {
        width: 100%;
    }
    .large-block {
        font-size: 17px;
        line-height: 20px;
    }
    .heading.heading-lg {
        font-size: 51px;
        line-height: 63px;
    }
    #coming-soon {
        h1 {
            letter-spacing: 0;
            font-size: 44px;
            line-height: 63px;
        }
    }
    #thanks {
        h1 {
            font-size: 46px;
            line-height: 68px;
        }
    }
}


/* Small devices */

@media (min-width: 576px) {
    .d-min-vh-100 {
        min-height: initial;
    }
    .navbar {
        padding: 37px;
    }
    .navbar-brand span {
        display: block;
    }
    .container {
        width: 100%;
    }
    .intro.container {
        padding: 140px 1.5rem;
    }
    .heading-alt,
    .heading {
        font-size: 80px;
        line-height: 92px;
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .container {
        width: 100%;
    }
    .heading-alt,
    .heading {
        font-size: 110px;
        line-height: 125px;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .navbar {
        padding: 1.5rem 3rem;
    }
    .navbar-brand span {
        display: inline-block;
    }
    .container {
        width: 75%;
    }
    .heading-alt,
    .heading {
        line-height: 125px;
    }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .navbar {
        padding: 1.5rem 3rem;
    }
    .navbar-brand span {
        display: inline-block;
    }
    .container {
        width: 53%;
    }
    .heading-alt,
    .heading {
        line-height: 125px;
    }
}